import React from 'react';
import PageLink from './page-link';
import Svg from './svg';
import Close from './close';
import axios from 'axios';
import A400 from '../animations/a400NoFade';

class ContactFormProduct extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email_error: '',
      email_focus: '',
      email: '',
      qcheck: '',
      product: '',
      product_id: '',
      success: false,
      visable: false,
    };
  }

  toggleForm = (visible) => {
    this.setState({
      email_error: '',
      email_focus: '',
      email: '',
      qcheck: '',
      product_id: this.props.productId, 
      product: this.props.productValue,
      success: false,
      visable: visible,
    });
  };

  submit = () => {
    if (typeof gtag != 'undefined' && process.env.ENV == 'prod') {
        gtag('event', 'screen_view', { 'app_name': 'Forma','screen_name': 'Atsisiuntimas' });
    }

    let valid = true;
    const that = this;
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const valid_email = re.test(String(this.state.email).toLowerCase());
    if (!valid_email) {
      valid = false;
      this.setState({ email_error: 'error' });
    }
    if (valid) {
      axios
        .post(process.env.GATSBY_CONTACT_PRODUCT_ENDPOINT, {
          email: this.state.email,
          product: this.state.product,
          product_id: this.state.product_id,
          qcheck: this.state.qcheck,
        })
        .then(function (response) {
          if (response.data.success) {
            that.setState({ success: true });
          }
          if (response.data.download_url) {
            window.open(response.data.download_url, '_blank', 'noreferrer');
          }
        })
        .catch(function (error) {
          alert(error);
        });
    }
  };

  render() {
    if (!this.state.visable) return null;

    let form = null;
    let message = null;
    if (this.state.success) {
      message = (
        <p
          className='success-message'
          id='contact-product-success'
          dangerouslySetInnerHTML={{
            __html: this.props.data.product_contact_form_success,
          }}
        />
      );
    } else {
      form = (
        <div className='form form--small'>
          <div className='form-top-small'>
            <div
              className={`input ${this.state.email_error} ${this.state.email_focus}`}
            >
              <label>{this.props.data.product_contact_form_email}</label>
              <input
                type='text'
                onChange={(event) =>
                  this.setState({ email: event.target.value })
                }
                onFocus={() =>
                  this.setState({ email_error: '', email_focus: 'focus' })
                }
                onBlur={() =>
                  this.setState({
                    email_focus: this.state.email ? 'focus' : '',
                  })
                }
              />
              <div
                className='error-message'
                dangerouslySetInnerHTML={{
                  __html: this.props.data.product_contact_form_email_error,
                }}
              />
            </div>
            <div
              style={{
                position: 'absolute',
                top: '-50000px',
                left: '-50000px',
              }}
            >
              <input
                type='text'
                name='qcheck'
                id='qcheck'
                onChange={(event) =>
                  this.setState({ qcheck: event.target.value })
                }
              />
            </div>

            <button className='btn-submit' onClick={this.submit}>
              <Svg name='submit' />
            </button>
          </div>
          <div className='form-bottom-small'>
            <div className={`form-small-agree`}>
              <div>{this.props.data.product_contact_form_agree}</div>{' '}
              <PageLink
                lang={this.props.lang}
                pages={this.props.pages}
                to='privacy-policy'
              >
                {this.props.data.product_contact_form_agree_link}
              </PageLink>
            </div>
          </div>
        </div>
      );
    }
    return (
      <div className='popup'>
        {/* <div className='popup__overlay'></div> */}
        <div className='popup__content'>
          <A400 className='popup-liquid-1' />
          <Close
            text={this.props.data.close}
            onClick={() => this.toggleForm(false)}
          />
          <div className='form-small'>
            <div
              className='h2 h2--secondary'
              dangerouslySetInnerHTML={{
                __html: this.props.formTitle,
              }}
            />
            {form}
            {message}
          </div>
        </div>
      </div>
    );
  }
}

export default ContactFormProduct;
