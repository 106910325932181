import React from 'react';
import Layout from '../components/layout';
import Svg from '../components/svg';
import MilkLink from '../components/milk-link';
import Swiper from 'react-id-swiper';
import SEO from '../components/seo';
import 'swiper/swiper.css';
import A80 from '../animations/a80';
import A160 from '../animations/a160';
import A400 from '../animations/a400';
import StatsSmall from '../components/stats-small';
import ContactFormProduct from '../components/contact-form-product';

// export default ({ pageContext: { item, pages, lang, texts, key } }) => {
// <Svg name="external" />
class Product extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showMore: false,
      activeTable: 0,
      tableItems: [0, 0],
      swiper: null,
      clicked: false,
    };
  }

  // toggleShowMore = () => {
  //   this.setState({
  //     showMore: !this.state.showMore,
  //   });
  // };

  goPrev = () => {
    if (this.state.swiper !== null) {
      this.state.swiper.slidePrev();
    }
  };

  goNext = () => {
    if (this.state.swiper !== null) {
      this.state.swiper.slideNext();
    }
  };

  render() {
    // let btn;
    // if (this.state.showMore) {
    //   btn = (
    //     <button className='btn btn-more' onClick={this.toggleShowMore}>
    //       {this.props.pageContext.texts.product_page_show_less}
    //       <Svg name='minus' />
    //     </button>
    //   );
    // } else {
    //   btn = (
    //     <button className='btn btn-more' onClick={this.toggleShowMore}>
    //       {this.props.pageContext.texts.product_page_show_more}
    //       <Svg name='plus' />
    //     </button>
    //   );
    // }

    // const products = this.props.pageContext.pages.filter(
    //   (page) => page[this.props.pageContext.lang].template === 'products'
    // )[0][this.props.pageContext.lang].items;
    // const prev_key =
    //   this.props.pageContext.key === 0
    //     ? products.length - 1
    //     : this.props.pageContext.key - 1;
    // const next_key =
    //   this.props.pageContext.key === products.length - 1
    //     ? 0
    //     : this.props.pageContext.key + 1;

    const contactsPage = this.props.pageContext.pages.filter(
      (page) => page[this.props.pageContext.lang].template === 'contacts'
    )[0];

    const params = {
      //navigation: {
      //    nextEl: '.nav-next',
      //    prevEl: '.nav-prev'
      //},
      slidesPerView: 'auto',
    };

    let child;
    const openForm = () => {
      child.toggleForm(true);
    };

    return (
      <Layout
        pages={this.props.pageContext.pages}
        lang={this.props.pageContext.lang}
        texts={this.props.pageContext.texts}
        langs={this.props.pageContext.langs}
      >
        <SEO
          description={this.props.pageContext.product.seo_description}
          title={this.props.pageContext.product.seo_title}
          image={this.props.pageContext.product.image}
        />
        <div className='layer-top layer-product-hero'>
          <A160 className='product-liquid-1' />
          <div className='relative'>
            <div className='small-wrap'>
              <div className='product-hero product-hero--small-padding flex'>
                <div className='left'>
                  <h1
                    data-sal='slide-left'
                    data-sal-duration='1000'
                    dangerouslySetInnerHTML={{
                      __html: this.props.pageContext.product.title,
                    }}
                  />
                  <h3
                    data-sal='slide-up'
                    data-sal-duration='1000'
                    className='h3 small-margin'
                    dangerouslySetInnerHTML={{
                      __html: this.props.pageContext.product.subtitle,
                    }}
                  />
                  <div
                    data-sal='slide-up'
                    data-sal-duration='1000'
                    className='short-text'
                    dangerouslySetInnerHTML={{
                      __html: this.props.pageContext.product.short_text,
                    }}
                  />

                  <div
                    className='product-hero__button-holder'
                    data-sal='slide-up'
                    data-sal-duration='1000'
                  >
                    <MilkLink
                      className='btn'
                      to={`/${this.props.pageContext.lang}${
                        contactsPage[this.props.pageContext.lang].url
                      }`}
                    >
                      {this.props.pageContext.texts.product_contact_btn}
                    </MilkLink>
                    {this.props.pageContext.product.data_request_title &&
                      this.props.pageContext.product.data_request_title !==
                        null && (
                        <button
                          className='btn btn--secondary'
                          onClick={openForm}
                        >
                          {this.props.pageContext.texts.product_request_btn}
                        </button>
                      )}
                  </div>
                  {this.props.pageContext.product.applications_title &&
                    this.props.pageContext.product.applications_title !==
                      null && (
                      <h4
                        data-sal='slide-up'
                        data-sal-duration='1000'
                        className='h4'
                        dangerouslySetInnerHTML={{
                          __html:
                            this.props.pageContext.product.applications_title,
                        }}
                      />
                    )}
                  {this.props.pageContext.product.applications_text &&
                    this.props.pageContext.product.applications_text !==
                      null && (
                      <div
                        data-sal='slide-up'
                        data-sal-duration='1000'
                        className='short-text'
                        dangerouslySetInnerHTML={{
                          __html:
                            this.props.pageContext.product.applications_text,
                        }}
                      />
                    )}
                </div>
                <div className='right'>
                  <A400 className='product-liquid-2' />
                  <img
                    data-sal='slide-down'
                    data-sal-duration='1000'
                    className='product-image'
                    src={this.props.pageContext.product.image}
                    alt=''
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='wrap-full pl-xl pr-xl product-applications-title'>
          <h4
            data-sal='slide-up'
            data-sal-duration='1000'
            className='h4'
            dangerouslySetInnerHTML={{
              __html: this.props.pageContext.product.applications_subtitle,
            }}
          />
        </div>

        <StatsSmall data={this.props.pageContext.product} />
        <div className="stats">
            {this.props.pageContext.product.product_id == 6 && this.props.pageContext.product.blocks.map((block,index) => {
                return (
                    <div key={index}>
                        <div
                            data-sal-delay={index % 3 * 400}
                            data-sal="slide-up"
                            className={`percent-item percent-item-${index}`}
                            data-sal-duration="1000">
                            <div className="circle">
                                <Svg name="round" className="round" />
                                <svg strokeLinecap="round" data-offset={ Math.round(456 - 4.56 * block.percent) } xmlns="http://www.w3.org/2000/svg" viewBox="0 0 153 153" className="round-move">
                                    <circle cx="76.5" cy="76.5" r="72.5" strokeWidth="4" fill="none"></circle>
                                </svg>
                                <b dangerouslySetInnerHTML={{__html: block.text }} /><span>%</span>
                            </div>
                            <div className="h3" dangerouslySetInnerHTML={{__html: block.label }} />
                        </div>
                        <style type="text/css" dangerouslySetInnerHTML={{__html: `.sal-animate.percent-item-${index} .round-move { stroke-dashoffset: ${ Math.round(456 - 4.56 * block.percent) } !important}`  }}>
                        </style>
                    </div>
                )
            })}
        </div>
        {this.props.pageContext.product.tables.length > 0 && (
          <div className='tabs'>
            <A80
              className='product-liquid-3'
              opacity='0.1'
              style1={{ stopColor: '#e8efff', stopOpacity: '0' }}
              style2={{ stopColor: '#e8efff' }}
            />
            <A400
              className='product-liquid-4'
              opacity='0.1'
              style1={{ stopColor: '#E8EFFF', stopOpacity: '0' }}
              style2={{ stopColor: '#E8EFFF' }}
            />

            {/* <div
            data-sal='slide-up'
            data-sal-duration='1000'
            className='tab-names-wrap'
          >
            <div className='tab-names'>
              <div className='small-wrap'>
                {this.props.pageContext.product.tables.map((table, index) => {
                  return (
                    <button
                      key={index}
                      onClick={() => {
                        this.setState({ clicked: true, activeTable: index });
                      }}
                      className={
                        this.state.activeTable === index
                          ? 'tab-name active'
                          : 'tab-name'
                      }
                    >
                      {table.title}
                    </button>
                  );
                })}
              </div>
            </div>
          </div> */}
            <div data-sal='slide-up' data-sal-duration='1000'>
              {this.props.pageContext.product.tables.map((table, index) => {
                if (this.state.activeTable === index) {
                  return (
                    <div key={index} className='subtab-names'>
                      <button className='nav nav-prev' onClick={this.goPrev}>
                        <Svg name='back' />
                      </button>
                      <button className='nav nav-next' onClick={this.goNext}>
                        <Svg name='back' />
                      </button>
                      <Swiper
                        getSwiper={(node) => {
                          this.setState({ swiper: node });
                        }}
                        className='subtab-names-container'
                        {...params}
                      >
                        {table.items.map((subtable, key) => {
                          return (
                            <button
                              key={key}
                              onClick={() =>
                                this.setState({
                                  clicked: true,
                                  tableItems: [
                                    index === 0
                                      ? key
                                      : this.state.tableItems[0],
                                    index === 1
                                      ? key
                                      : this.state.tableItems[1],
                                  ],
                                })
                              }
                              className={
                                this.state.tableItems[index] === key
                                  ? 'subtab-name active'
                                  : 'subtab-name'
                              }
                            >
                              {subtable.title}
                            </button>
                          );
                        })}
                      </Swiper>
                    </div>
                  );
                }
                return null;
              })}
            </div>
            {this.props.pageContext.product.tables.map((table, index) => {
              return table.items.map((subtable, key) => {
                if (
                  this.state.activeTable === index &&
                  this.state.tableItems[index] === key
                ) {
                  if (key === 0 && index === 0 && !this.state.clicked) {
                    return (
                      <div key={index * 100 + key} className='tab-content'>
                        <div
                          className='wrap-full pl-xxl pr-xl'
                          data-sal='slide-up'
                          data-sal-duration='1000'
                          dangerouslySetInnerHTML={{ __html: subtable.text }}
                        />
                      </div>
                    );
                  }
                  return (
                    <div key={index * 100 + key} className='tab-content'>
                      <div
                        className='wrap-full pl-xxl pr-xl'
                        dangerouslySetInnerHTML={{ __html: subtable.text }}
                      />
                    </div>
                  );
                }
                return null;
              });
            })}
          </div>
        )}

        {this.props.pageContext.product.packaging_blocks.length == 0 && !this.props.pageContext.product.solutions_title ? 
        <div className='layer layer--smaller'>
          <A160 className='product-liquid-5' />
          <A400 className='product-liquid-6' />
          <div className='wrap-full pl-xl pr-xl'>
            <h2
              className='h2 h2--secondary'
              data-sal='slide-left'
              data-sal-duration='1000'
              dangerouslySetInnerHTML={{
                __html: this.props.pageContext.product.packaging_text,
              }}
            />
          </div>
          <div className='wrap-full pl-s pr-s'>
            <div className='weights'>
              {this.props.pageContext.product.packaging_blocks.map(
                (packaging, index) => {
                  let n = index + 1;
                  return (
                    <div
                      data-sal-delay={index * 200}
                      data-sal='slide-up'
                      data-sal-duration='1000'
                      key={index}
                      className={`weight-${n}`}
                    >
                      <Svg name='weight-1' className='weight' />
                      <Svg name='triangle' className='triangle' />
                      <div>
                        {packaging}
                        <span>kg</span>
                      </div>
                    </div>
                  );
                }
              )}
            </div>
          </div>
        </div>
        :
        <div className='layer'>
          <A160 className='product-liquid-5' />
          <A400 className='product-liquid-6' />
          <div className='wrap-full pl-xl pr-xl'>
            <h2
              className='h2 h2--secondary'
              data-sal='slide-left'
              data-sal-duration='1000'
              dangerouslySetInnerHTML={{
                __html: this.props.pageContext.product.packaging_text,
              }}
            />
          </div>
          <div className='wrap-full pl-s pr-s'>
            <div className='weights'>
              {this.props.pageContext.product.packaging_blocks.map(
                (packaging, index) => {
                  let n = index + 1;
                  return (
                    <div
                      data-sal-delay={index * 200}
                      data-sal='slide-up'
                      data-sal-duration='1000'
                      key={index}
                      className={`weight-${n}`}
                    >
                      <Svg name='weight-1' className='weight' />
                      <Svg name='triangle' className='triangle' />
                      <div>
                        {packaging}
                        <span>kg</span>
                      </div>
                    </div>
                  );
                }
              )}
            </div>
          </div>
        </div>
        }
        {this.props.pageContext.product.solutions_title && this.props.pageContext.product.solutions_title !== null && (
          <div className='wrap-full pl-xl pr-xxl group group-2 layer-last'>
            <div className='left'>
              <h3
                data-sal='slide-right'
                data-sal-duration='1000'
                className='h3'
                dangerouslySetInnerHTML={{
                  __html: this.props.pageContext.product.solutions_title,
                }}
              />
            </div>
            <div className='right'>
              <div
                data-sal='slide-left'
                data-sal-duration='1000'
                className='text'
                dangerouslySetInnerHTML={{
                  __html: this.props.pageContext.product.solutions_text,
                }}
              />
            </div>
          </div>
        )}
        {/* <div className='pagination'>
          <div
            data-sal='slide-right'
            data-sal-duration='1000'
            className='page-link'
          >
            <MilkLink
              to={`/${this.props.pageContext.lang}${products[prev_key].url}`}
            >
              <div className='page-label'>
                <div className='page-circle'>
                  <Svg name='back' />
                </div>
                {this.props.pageContext.texts.product_page_prev}
              </div>
              <b
                dangerouslySetInnerHTML={{ __html: products[prev_key].title }}
              />
            </MilkLink>
          </div>
          <div
            data-sal='slide-left'
            data-sal-duration='1000'
            className='page-link'
          >
            <MilkLink
              to={`/${this.props.pageContext.lang}${products[next_key].url}`}
            >
              <b
                dangerouslySetInnerHTML={{ __html: products[next_key].title }}
              />
              <div className='page-label'>
                {this.props.pageContext.texts.product_page_next}
                <div className='page-circle'>
                  <Svg name='back' />
                </div>
              </div>
            </MilkLink>
          </div>
        </div> */}
        <ContactFormProduct
          ref={(node) => {
            child = node;
          }}
          data={this.props.pageContext.texts}
          pages={this.props.pageContext.pages}
          lang={this.props.pageContext.lang}
          formTitle={this.props.pageContext.product.data_request_title}
          productValue={this.props.pageContext.product.title}
          productId={this.props.pageContext.product.product_id}
        />
      </Layout>
    );
  }
}

export default Product;
