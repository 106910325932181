import React from "react"

const ANoFade = ({ className, style1, style2, opacity }) => (
    <div
        className={`liquid liq-400 ${className}`} >
        <svg
            version="1.1" baseProfile="basic" id="Layer_1"
            xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 160 160"
            >
            <radialGradient id={`mid_1_${className}`} cx="-599.261" cy="462.8372" r="0.5" gradientTransform="matrix(160 0 0 -160 95980 74115.7109)" gradientUnits="userSpaceOnUse">
            <stop  offset="0" style={style1 ? style1 : {stopColor:"#E8EFFF",stopOpacity:0}}/>
            <stop  offset="1" style={style2 ? style2 : {stopColor:"#2029BD"}}/>
            </radialGradient>
            <path id="mid" opacity={opacity ? opacity : '0.1'} fill={`url(#mid_1_${className})`} d="M80,0c34.46,0,63.83,13.79,75.15,44.51C158.27,52.96,160,70.28,160,80
            c0,27.17-19.15,46.38-40,60.94C107.18,149.89,97.01,160,80,160c-16.04,0-39.77-19.12-52.44-27.34C5.73,118.49,0,108.14,0,80
            c0-22.27,7.9-33.03,27.9-53.03S58.08,0,80,0z">

            <animate
            repeatCount="indefinite"
            fill="#454599"
            attributeName="d"
            dur="12s"
            values="M95,2.58c32.98,8.38,47.42,35.83,51.01,67.07c0.99,8.6,8.91,37.1,6.62,46.12
            c-6.4,25.21-23.65,28.36-47.04,36.8c-14.37,5.19-23.32,7.14-39.59,3.01c-15.35-3.9-27.13-20.33-37.31-31.04
            C11.15,106.09-1.5,92.98,5.13,66.87c5.25-20.66,9.6-30.18,40.84-36.46S74.03-2.75,95,2.58z;

            M93,4.47c33.7,4.64,44.56,21.46,51.62,52.11c1.94,8.44,13,35.88,11.73,45.09
            c-3.55,25.76-20.34,30.82-42.63,41.82c-13.71,6.76-24.13,8.88-40.77,6.59c-15.69-2.16-30.08-15.52-41.4-25.03
            C12.05,108.7,0.63,96.24,4.31,69.55c2.91-21.11,6.17-31.06,36.51-40.79S71.56,1.52,93,4.47z;

            M80,0c34.46,0,63.83,13.79,75.15,44.51C158.27,52.96,160,70.28,160,80
            c0,27.17-19.15,46.38-40,60.94C107.18,149.89,97.01,160,80,160c-16.04,0-39.77-19.12-52.44-27.34C5.73,118.49,0,108.14,0,80
            c0-22.27,7.9-33.03,27.9-53.03S58.08,0,80,0z;

            M93,4.47c33.7,4.64,44.56,21.46,51.62,52.11c1.94,8.44,13,35.88,11.73,45.09
            c-3.55,25.76-20.34,30.82-42.63,41.82c-13.71,6.76-24.13,8.88-40.77,6.59c-15.69-2.16-30.08-15.52-41.4-25.03
            C12.05,108.7,0.63,96.24,4.31,69.55c2.91-21.11,6.17-31.06,36.51-40.79S71.56,1.52,93,4.47z;

            M95,2.58c32.98,8.38,47.42,35.83,51.01,67.07c0.99,8.6,8.91,37.1,6.62,46.12
            c-6.4,25.21-23.65,28.36-47.04,36.8c-14.37,5.19-23.32,7.14-39.59,3.01c-15.35-3.9-27.13-20.33-37.31-31.04
            C11.15,106.09-1.5,92.98,5.13,66.87c5.25-20.66,9.6-30.18,40.84-36.46S74.03-2.75,95,2.58z;

            M80,0c34.46,0,63.83,13.79,75.15,44.51C158.27,52.96,160,70.28,160,80
            c0,27.17-19.15,46.38-40,60.94C107.18,149.89,97.01,160,80,160c-16.04,0-39.77-19.12-52.44-27.34C5.73,118.49,0,108.14,0,80
            c0-22.27,7.9-33.03,27.9-53.03S58.08,0,80,0z;

            M95,2.58c32.98,8.38,47.42,35.83,51.01,67.07c0.99,8.6,8.91,37.1,6.62,46.12
            c-6.4,25.21-23.65,28.36-47.04,36.8c-14.37,5.19-23.32,7.14-39.59,3.01c-15.35-3.9-27.13-20.33-37.31-31.04
            C11.15,106.09-1.5,92.98,5.13,66.87c5.25-20.66,9.6-30.18,40.84-36.46S74.03-2.75,95,2.58z
            ">
            </animate>

            </path>
        </svg>
    </div>
)

export default ANoFade
